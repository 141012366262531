/* SimpleModal.css */

.modal-overlay {
  position: fixed; /* Позиционирование элемента относительно вьюпорта браузера */
  top: 0; /* Верхний край элемента прижимается к верхней границе вьюпорта */
  left: 0; /* Левый край элемента прижимается к левой границе вьюпорта */
  width: 100%; /* Ширина элемента занимает 100% ширины вьюпорта */
  height: 100%; /* Высота элемента занимает 100% высоты вьюпорта */
  background-color: rgba(0, 0, 0, 0.6); /* Полупрозрачный черный фон */
  display: flex; /* Применение flexbox для центрирования внутреннего содержимого */
  align-items: center; /* Центрирование содержимого по вертикали */
  justify-content: center; /* Центрирование содержимого по горизонтали */
  z-index: 1000; /* Установка высокого z-index для позиционирования элемента над другими элементами */
}

.modal-content {
  background: white; /* Белый фон для внутреннего содержимого модального окна */
  padding: 5px; /* Отступ внутри блока */
  border-radius: 8px; /* Скругление углов блока */
}

.modal-image {
  max-width: 100%; /* Максимальная ширина изображения равна 100% ширины родителя */
  max-height: 80vh; /* Максимальная высота изображения равна 80% высоты вьюпорта */
  display: block; /* Изображение отображается как блочный элемент */
  margin: 0 auto; /* Автоматический отступ справа и слева для центрирования изображения */
}
