.organization-card-container {
  padding: 20px; /* Отступы внутри контейнера */
  background: #fff; /* Фон контейнера */
  border-radius: 30px; /* Закругленные углы контейнера */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Тень контейнера */
  max-width: 600px; /* Максимальная ширина контейнера */
  margin: 20px auto; /* Отступы по горизонтали и вертикали, автоматическое центрирование */
}

/* Стили для заголовка */
.organization-card-container h1 {
  font-size: 25px; /* Устанавливаем размер шрифта */
  font-weight: bold; /* Делаем шрифт жирным */
  margin-top: 0; /* Убираем верхний отступ */
  margin-bottom: 0.3em; /* Отступ снизу для разделения текста и следующего элемента */
  /* Добавьте дополнительные стили по необходимости */
}

/* Стили для кнопки "Назад" */
.back-button {
  background: none; /* Фон кнопки прозрачный */
  border: none; /* Границы отсутствуют */
  cursor: pointer; /* Курсор меняется на указатель при наведении */
}

/* Стили для слайдера изображений */
.image-gallery .slick-slide {
  height: 300px; /* Задаем высоту слайда */
  display: flex; /* Отображение элемента как флекс-контейнера */
  justify-content: center; /* Выравнивание по горизонтали по центру */
  align-items: center; /* Выравнивание по вертикали по центру */
}

.image-gallery .slick-slide img {
  width: 100%; /* Изображение занимает всю доступную ширину */
  height: 300px; /* Высота изображения */
  object-fit: cover; /* Масштабирование изображения с сохранением пропорций и покрытием всего элемента */
  border-radius: 30px; /* Закругленные углы изображения, если нужно */
}

/* Стили для блока с адресом */
.address {
  margin-top: 30px; /* Отступ сверху */
  font-size: 1.1em; /* Размер шрифта */
  color: #333; /* Цвет текста */
  margin-bottom: 10px; /* Отступ снизу */
}

/* Стили для блока с описанием */
.description {
  margin-bottom: 20px; /* Отступ снизу */
  line-height: 1.6; /* Межстрочный интервал */
  color: #666; /* Цвет текста */
}

/* Стили для кнопки в описании */
.description button {
  background: none; /* Фон кнопки прозрачный */
  border: none; /* Границы отсутствуют */
  color: blue; /* Цвет текста кнопки */
  cursor: pointer; /* Курсор меняется на указатель при наведении */
  padding: 0; /* Убираем внутренние отступы */
}

/* Стили для контейнера с кнопками действий */
.buttons-container {
  display: flex; /* Отображение элементов как флекс-контейнера */
  flex-direction: column; /* Направление элементов - вертикальное */
  align-items: center; /* Выравнивание элементов по центру по вертикали */
  gap: 10px; /* Расстояние между элементами */
}

/* Стили для кнопок действий */
.action-button {
  display: block; /* Отображение как блок, чтобы кнопка занимала всю ширину */
  width: 80%; /* Ширина кнопки */
  padding: 10px 20px; /* Внутренние отступы */
  margin: 5px 0; /* Отступы сверху и снизу, убираем боковые отступы */
  border: none; /* Границы отсутствуют */
  border-radius: 15px; /* Закругленные углы кнопки */
  cursor: pointer; /* Курсор меняется на указатель при наведении */
  text-align: center; /* Выравнивание текста по центру */
  font-weight: bold; /* Делаем шрифт жирным */
  /* Другие стили для кнопок... */
}

/* Стили для кнопки с белым фоном */
.action-button.white {
  background: #ffffff; /* Фон кнопки белый */
  color: #333; /* Цвет текста кнопки */
  border: 1px solid #ddd; /* Граница с тенью */
}

/* Стили для кнопки с синим фоном */
.action-button.blue {
  background: #007bff; /* Фон кнопки синий */
  color: #ffffff; /* Цвет текста кнопки */
}

/* Стили для кнопки с оранжевым фоном */
.action-button.orange {
  background: #ffa500; /* Фон кнопки оранжевый */
  color: #ffffff; /* Цвет текста кнопки */
}

/* Стили для кнопки с красным фоном */
.action-button.red {
  background: #ff0000; /* Фон кнопки красный */
  color: #ffffff; /* Цвет текста кнопки */
}

/* Стили для кнопки с зеленым фоном */
.action-button.green {
  background: #1fc646; /* Фон кнопки зеленый */
  color: #ffffff; /* Цвет текста кнопки */
}

/* При наведении на кнопку */
.action-button:hover {
  opacity: 0.8; /* Уменьшение прозрачности при наведении */
}

/* Стили для фона страницы */
.page-background {
  background: #ECF0F6; /* Фон для конкретной страницы */
  min-height: 100vh; /* Минимальная высота равна высоте области просмотра */
  padding: 20px; /* Отступы вокруг карточки, если нужно */
}
