.organization-list-screen {
  background: #ECF0F6; /* Фон для экрана списка организаций */
  min-height: 100vh; /* Минимальная высота экрана - 100% высоты области просмотра */
}

.header {
  display: flex;
  align-items: center;
  padding: 16px;
  background: #FFFFFF; /* Фон шапки */
  /* Добавьте стили для шапки в соответствии с дизайном */
}

.location-info h1,
.location-info h2 {
  margin: 0;
  /* Добавьте стили для текста, чтобы соответствовать дизайну */
}

.organization-list {
  padding: 16px;
}

.organization-item {
  background: #FFFFFF; /* Фон элемента списка организаций */
  border-radius: 8px;
  margin: 16px; /* Внешний отступ вокруг элемента */
  overflow: hidden;
  display: flex;
  align-items: center;
  cursor: pointer;
  /* Добавьте остальные стили элемента списка организаций */
}

.organization-item:not(:last-child) {
  margin-bottom: 16px; /* Отступ между элементами, кроме последнего */
}

.organization-logo {
  width: 140px; /* Ширина логотипа организации */
  border-radius: 20px;
  margin: 12px;
  object-fit: cover; /* Заполнение области изображения с сохранением пропорций */
}

.organization-info {
  padding: 30px;
  /* Добавьте стили для внутреннего отступа и текста */
}

.organization-info h3 {
  font-weight: bold;
  margin: 0 0 8px 0;
  /* Стилизуйте заголовок организации в соответствии с дизайном */
}

.organization-info p {
  margin: 0;
  /* Стилизуйте адрес организации в соответствии с дизайном */
}

/* Анимации для появления и исчезновения */
.fade-in {
  animation: fadeIn 1000ms ease-in-out;
}

.fade-out {
  animation: fadeOut 1000ms ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}
