/* LocationAndCategorySelectScreen.css */

.container {
  padding: 20px; /* Отступы внутри контейнера */
}

.header {
  font-size: 24px; /* Размер шрифта заголовка */
  font-weight: bold; /* Жирность шрифта заголовка */
  margin-bottom: 20px; /* Отступ снизу для заголовка */
}

.list {
  list-style: none; /* Убираем стандартные маркеры для списка */
  padding: 0; /* Убираем отступы внутри списка */
}

.listItem {
  margin-bottom: 10px; /* Отступ снизу для каждого элемента списка */
}

.button {
  display: flex; /* Элементы внутри кнопки выстроены в ряд */
  align-items: center; /* Выравнивание элементов кнопки по центру */
  background: white; /* Фон кнопки */
  border: 1px solid #ddd; /* Граница кнопки */
  border-radius: 8px; /* Скругление углов кнопки */
  padding: 10px 20px; /* Отступы внутри кнопки */
  width: 100%; /* Ширина кнопки */
  text-align: left; /* Выравнивание текста внутри кнопки по левому краю */
  font-size: 18px; /* Размер шрифта внутри кнопки */
  font-weight: normal; /* Нормальный жирность шрифта внутри кнопки */
  margin-bottom: 10px; /* Отступ снизу для кнопки */
}

.selectedButton {
  background: #ececec; /* Фон для выбранной кнопки */
}

.flag {
  width: 30px; /* Ширина флага */
  height: auto; /* Высота флага (автоматическая, сохраняется пропорция) */
  margin-right: 10px; /* Отступ справа от флага */
}

.vector-dashed-line {
  content: ''; /* Псевдоэлемент без содержимого */
  display: block; /* Псевдоэлемент отображается как блочный элемент */
  height: 50px; /* Высота псевдоэлемента */
  position: relative; /* Позиционирование относительно родительского элемента */
  margin-left: 20px; /* Отступ слева от псевдоэлемента */
}

.vector-dashed-line::after {
  content: ''; /* Псевдоэлемент без содержимого */
  position: absolute; /* Абсолютное позиционирование псевдоэлемента */
  left: 0; /* Псевдоэлемент прижат к левому краю родителя */
  top: 0; /* Псевдоэлемент прижат к верхнему краю родителя */
  bottom: 0; /* Псевдоэлемент прижат к нижнему краю родителя */
  width: 1px; /* Ширина псевдоэлемента */
  background: repeating-linear-gradient(
    top,
    #676B71,
    #676B71 10px,
    transparent 10px,
    transparent 20px
  ); /* Фон псевдоэлемента в виде пунктирной линии */
}

.back-button {
  background: none; /* Фон кнопки "Назад" прозрачный */
  border: none; /* Без границы */
  cursor: pointer; /* Курсор в виде указателя */
}

.step-indicator {
  width: 32px; /* Ширина индикатора шага */
  height: 32px; /* Высота индикатора шага */
  border-radius: 50%; /* Круглая форма индикатора */
  display: flex; /* Элементы внутри индикатора выстроены в ряд */
  align-items: center; /* Выравнивание элементов по центру */
  justify-content: center; /* Горизонтальное выравнивание элементов по центру */
  font-weight: bold; /* Жирность шрифта внутри индикатора */
  margin-right: 8px; /* Отступ справа от индикатора */
}

.country-info {
  display: flex; /* Элементы внутри информации о стране выстроены в ряд */
  align-items: center; /* Выравнивание элементов по центру */
  background-color: #ECF0F6; /* Фон информации о стране */
  border: 1px solid #D1D5DB; /* Граница информации о стране */
  padding: 12px 20px; /* Отступы внутри информации о стране */
  border-radius: 9999px; /* Овальная форма информации о стране */
  cursor: pointer; /* Курсор в виде указателя */
  margin-left: 8px; /* Отступ слева от информации о стране */
}

.flag-icon {
  width: 32px; /* Ширина иконки флага */
  height: 24px; /* Высота иконки флага */
  margin-right: 8px; /* Отступ справа от иконки флага */
}

.dotted-line {
  border-left: 1px dashed #676B71; /* Пунктирная линия слева */
  height: 35px; /* Высота пунктирной линии */
  margin: 5px 1; /* Отступы сверху и снизу линии */
}

.fade-exit {
  opacity: 1;
  transform: translateY(0);
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 1000ms, transform 1000ms; /* Анимация исчезновения */
}

.fade-enter {
  opacity: 0;
  transform: translateY(100%);
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 1000ms, transform 1000ms; /* Анимация появления */
}

.pink-text {
  color: #ff0080; /* Розовый цвет текста */
}

.animate-out {
  animation: slideOut 1000ms forwards; /* Анимация выезда вверх */
}

@keyframes slideOut {
  to {
    transform: translateY(-100%);
    opacity: 0; /* Анимация исчезновения */
  }
}
